<template>
  <v-container fluid style="margin-bottom: 100px">
    <v-row>
      <v-col cols="12">
      <div class="d-flex">
          <v-breadcrumbs :items="breadcumbs">
              <template v-slot:item="{ item }">
                  <v-breadcrumbs-item
                      :to="item.href"
                      class="text-subtitle-2 crumb-item"
                      :disabled="item.disabled"
                      exact
                  >
                      {{ item.text }}
                  </v-breadcrumbs-item>
              </template>
          </v-breadcrumbs>
          <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
              <v-layout align-center pr-4>
              <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
              <v-layout column>
                  <div>
                  <strong>{{ snackbar.title }}</strong>
                  </div>
                  <div>{{ snackbar.text }}</div>
              </v-layout>
              </v-layout>
              <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
              <v-icon>clear</v-icon>
              </v-btn>
          </v-snackbar>
          <v-spacer></v-spacer>
      </div>
      </v-col>
    </v-row>
    
  <!-- <v-container fluid class="d-flex flex-column" style="min-height: calc(100vh - 100px);"> -->
    <v-row align="center" justify="center" >
      <v-col cols="12">
                <v-card class="p-2" outlined>
                    <v-container fluid>
                        <v-row>
                            <!-- <v-col class="col-12" sm="6" md="2" lg="1">
                                <v-autocomplete
                                    solo
                                    v-model="periode"
                                    :items="periodes"
                                    item-value="periode_id"
                                    item-text="periode"
                                    label="periode"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col> -->

                            <v-col class="col-12" sm="6" md="2" lg="2">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="region"
                                    :items="regions"
                                    item-value="region_id"
                                    item-text="descr"
                                    label="Region"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    :disabled="disabled_region"
                                    @change="(event) => handleRegionClick(event)"
                                ></v-autocomplete>
                            </v-col>

                            <v-col class="col-12" sm="6" md="2" lg="2">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="subregion"
                                    :items="subregions"
                                    item-value="subregion_id"
                                    item-text="descr"
                                    label="Sub Region"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    :disabled="disabled_subregion"
                                    @change="(event) => getOffice(event)"
                                ></v-autocomplete>
                            </v-col>

                            <v-col class="col-12" sm="6" md="2" lg="2">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="office"
                                    :items="offices"
                                    item-value="office_id"
                                    item-text="office"
                                    label="Office"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    :disabled="disabled_office"
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-12" sm="6" md="2" lg="2" >
                                <v-btn class="border-12" style="padding: 20px;"
                                color="info" elevation="2" small @click="getSalesAnalysis()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
    </v-row>

    <v-row class="mt-2">      
      <div class="col-12 col-lg-12">
          <div class="card elevation-5">
              <div class="card-body">
                  <p class="card-title">Monthly Omzet</p>
                  <div id="chartContainer1" style="height: 300px; width: 100%;"></div>
              </div>
          </div>
      </div>
    </v-row>
    <v-row class="mt-2">      
      <div class="col-12 col-lg-12">
          <div class="card elevation-5">
              <div class="card-body">
                  <p class="card-title">Daily Omzet</p>
                  <div id="chartContainer2" style="height: 300px; width: 100%;"></div>
              </div>
          </div>
      </div>
    </v-row>
    
    <v-row class="mt-2">
      <v-col class="col-12" sm="12" md="12">
        <v-data-table
          dense
          :headers="header_sales_office"
          :items="data_sales_office"
          class="elevation-1 mt-2"
          :items-per-page="10"
          fixed-header
          :divider="true"
          :light="true">
          <template v-slot:top>
            <v-toolbar flat color="white" class="mt-2 mb-2">
                <div class="d-flex w-100">
                    <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="">mdi-table</v-icon> Office Omzet </h6>
                </div>
            </v-toolbar>
          </template>
          <template v-slot:[`item.total`]="{ item }">
            {{ $store.getters.convertToCurrencyNoFrac(item.total) }}
          </template>
          <template v-slot:[`item.retur`]="{ item }">
            {{ $store.getters.convertToCurrencyNoFrac(item.retur) }}
          </template>
          <template v-slot:[`item.y`]="{ item }">
            {{ $store.getters.convertToCurrencyNoFrac(item.y) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
              <td>
                  <v-tooltip top>
                      <template v-slot:activator="{ on }">
                          <v-icon small class="mr-2" @click="detail(item)" v-on="on">
                              mdi-send
                          </v-icon>
                      </template>
                      <span>Detail</span>
                  </v-tooltip>
              </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col class="col-12" sm="12" md="12">
        <v-data-table
            dense
            :headers="header_sales_detail"
            :items="data_sales_detail"
            class="elevation-1 mt-2"
            :items-per-page="10"
            fixed-header
            :divider="true"
            :light="true"
        >
        <template v-slot:top>
          <v-toolbar flat color="white" class="mt-2 mb-2">
              <div class="d-flex w-100">
                  <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="">mdi-table</v-icon> List Detail </h6>
                  <v-spacer></v-spacer>
                  <!-- button export excel -->
                  <v-btn
                    color="success"
                    class=""
                    v-if="data_sales_detail.length"
                    >
                        <v-icon>mdi-file-excel</v-icon>
                        <download-excel
                            class ="text"
                            :fetch = "exportExcel"
                            :data ="data_exports"
                            :before-generate = "startDownload"
                            :before-finish = "finishDownload">
                            Export Excel
                        </download-excel>
                    </v-btn>
              </div>
          </v-toolbar>
      </template>
          <template v-slot:[`item.TglPesan`]="{ item }">
          {{
            new Date(new Date(item.TglPesan) - (new Date().getTimezoneOffset() * 60000))
              .toISOString()
              .replace('T', ' ')
              .substr(0, 16)
          }}
        </template>
        <template v-slot:[`item.Panjang`]="{ item }">
            {{ $store.getters.convertToCurrencyNoFrac(item.Panjang) }}
        </template>
        <template v-slot:[`item.Lembar`]="{ item }">
            {{ $store.getters.convertToCurrencyNoFrac(item.Lembar) }}
        </template>
        <template v-slot:[`item.HargaSatuan`]="{ item }">
            {{ $store.getters.convertToCurrencyNoFrac(item.HargaSatuan) }}
        </template>
        <template v-slot:[`item.Total`]="{ item }">
            {{ $store.getters.convertToCurrencyNoFrac(item.Total) }}
        </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row class="mt-2" style="flex-grow: 1; height: 5%;">
      <v-col class="col-12" sm="6" md="12">
        <div style="height: 100%; width: 100%;"></div>
      </v-col>
    </v-row>



  </v-container>
</template>
<script>

import {kriMaster} from "@/backend-api/kri/kriMaster"
import {salesAnalysis} from "@/backend-api/kri/salesAnalysis"
import {backendApi} from "@/backend-api/backend-api-sr"
// import {converterLibrary} from "@/library/converterLibrary"

export default {
  data() {
      return {
        breadcumbs: [
            {
                text: 'Kencana Ritelindo Indonesia',
                disabled: false,
                href: '/admin/kri',
            },
            {
                text: 'Sales',
                disabled: true,
            },
            {
                text: 'Sales Analysis',
                disabled: true,
            }
        ],
        snackbar: {
            color: null,
            icon: null,
            mode: null,
            position: "top",
            text: null,
            timeout: 7500,
            title: null,
            visible: false
        },
        periode:'Date',
        periodes:[
          'Date',
          'Month',
        ],
        region:'',
        regions: [],
        subregion:'',
        subregions: [],
        office:'',
        offices: [],
        display: 'none',
        period_other_payment: '',
        dialog_other_payment: false,

        loading_other_payment: false,
        total_item_other_payment: 10,
        
        period_inv: '',
        dialog_inv_payment: false,
        pagination_inv_payment: 1,
        period: '',
        dialog_payment: false,
        pagination_payment: 1,
        loading_payment: false,
        
        option_item_payments:{},
        loading_inv_payment: false,
        
        total_item_inv_payments: 10,
        pagination_inv_payment: 1,

        header_sales_detail:[
          { text: 'TGL PESAN', value: 'TglPesan', align: 'left', width: '10%' },
          { text: 'INV NO.', value: 'CustomerOrderNo', align: 'left', width: '10%' },
          { text: 'ITEM', value: 'NamaBarang', align: 'left', width: '30%' },
          { text: 'LENGTH', value: 'Panjang', align: 'right', width: '10%' },
          { text: 'QTY', value: 'Lembar', align: 'right', width: '10%' },
          { text: 'ITEM PRICE', value: 'HargaSatuan', align: 'right', width: '15%' },
          { text: 'TOTAL', value: 'Total', align: 'right', width: '15%' },
        ],
        data_pendaftarans: [],
        header_sales_office:[
          { text: 'REGION', value: 'region', align: 'left', width: '10%' },
          { text: 'SUBREGION', value: 'subregion', align: 'left', width: '10%' },
          { text: 'OFFICE', value: 'office', align: 'left', width: '10%' },
          { text: 'SALES', value: 'total', align: 'right', width: '20%' },
          { text: 'RETUR', value: 'retur', align: 'right', width: '20%' },
          { text: 'TOTAL', value: 'y', align: 'right', width: '20%' },
          { text: 'Action', value: 'actions', sortable: false, width: '10%'},
        ],
        headersColumn: {
            'Order No': 'CustomerOrderNo',
            'Tanggal Pesan': 'TglPesan',
            'Office': 'office',
            'Nama Barang': 'NamaBarang',
            'Qty': 'Lembar',
            'Harga': 'HargaSatuan',
            'Total': 'Total'
        },
        data_sales_office: [],
        data_sales_detail: [],

        option_item_inv_payments:{},
        shop_summary_lastest: [],

        dialog_detail_paket: false,
        pagination_shop: 1, 
        last_month: '',
        loading_shop_last_month: false,
        detail_shop_summary_lastest: [],
        total_item_shops: 10,
        option_item_shops:{},
        date_from:'',
        modal: false,
        date_to: "",
        modal_to: false,
        showDataKategori: true,
        showDataKomoditi: true,
        showDataKomoditiEksternal: true,
        showDataKomoditiInternal: true,
        showDataKomoditiJasa: true,
        dt_per_kategori: [],
        dt_per_komoditi: [],
        salesOriginal:[],
        returOriginal:[],
        tanggalTabel:'',
        tabelSales: true,
        selOff: '',
        data_exports:[],
        x:  window.matchMedia("(max-width: 991px)"),
        disabled_region : false,
        disabled_subregion : false,
        disabled_office : false,
      }
  },
  async mounted(){
    // this.$store.dispatch('setOverlay', true)
    // this.getRegion()
    // this.getSubRegion()
    // this.getOffice()
    // this.getEnvConf()
    // this.getOffice()
    this.initialize()
    // this.searchSalesYear()
    // this.$store.dispatch('setOverlay', false)
  },
  methods:{
    async getEnvConf(){
    },

    async initialize(){
        this.$store.dispatch('setOverlay', true)        

        await axios.get(`${process.env.VUE_APP_URL}/api/kri/sales/salesAnalysis/envConfGetFilter`, { 
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${this.$store.getters.isTokenUser}`
            } 
        })
        .then(res => {
            console.log("Region = "+res.data.select_region);
            console.log("SubRegion = "+res.data.select_subregion);
            console.log("Office = "+res.data.select_office);
            
            if(res.data.select_region){
                this.getRegion()
                this.region = res.data.select_region
                this.disabled_region = true
            } else {
                this.getRegion()
                this.disabled_region = false
            }

            if(res.data.select_subregion){
                this.getSubRegion()
                this.subregion = res.data.select_subregion
                this.disabled_subregion = true
            } else {
              this.getSubRegion()
                this.disabled_subregion = false
            }            

            if(res.data.select_office){
              this.getOffice()
                this.office = res.data.select_office
                this.disabled_office = true
            } else {
                this.getOffice()
                this.disabled_office = false
            }

            this.$store.dispatch('setOverlay', false)
        })
        .catch(err => {
            this.$store.dispatch('setOverlay', false)
            if(err.response.status == '401') {
                this.$router.push('/');
            } else {
                Swal.fire({
                    text: err.response.data.data,
                    icon: 'error',
                })
            }
        })
    },

    
    
    async detail(event){
        this.$store.dispatch('setOverlay', true)
                
        // await axios.post(`${process.env.VUE_APP_URL}/api/kri/sales/salesAnalysis/officeSum`, {
        //     'period': event.dataPoint.period,
        //     'region' : this.region,
        //     'subregion' : this.subregion,
        //     'office' : this.office
        // }, { 
        //     headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        // })
        // .then(res => {
        //     this.data_sales_office  = res.data.data
        // })
        // console.log(this.data_sales_office);

        let reqBody = {
          'period': event.period,
          'region' : event.region,
          'subregion' : event.subregion,
          'office_id' : event.office_id
        }
        const respData = await salesAnalysis.officeSumDetails('', reqBody)
        if (respData.status === 200) {
            this.data_sales_detail = respData.data.data
            this.$store.dispatch('setOverlay', false)
        } else {
            this.$store.dispatch('setOverlay', false)
            this.snackbar = {
                color: "error",
                icon: "mdi-alert-circle",
                mode: "multi-line",
                position: "top",
                timeout: 7500,
                title: "Warning",
                text: 'Failed to retrieve data',
                visible: true
            };
            // this.$store.dispatch('setOverlay', false)
        }

    },

    async exportExcel(){
        //   await axios.post(`${process.env.VUE_APP_URL}/api/kri/sales/storeVisit/getExportExcel`, {
        //       'period_type': this.periode,
        //       'region' : this.region,
        //       'subregion' : this.subregion,
        //       'office_id' : this.office,
        //       'date_from' : "20250301",
        //       'date_to' : "20250331"
        //   }, { 
        //       headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        //   })
        //   .then(res => {
        //       this.data_exports  = res.data.data
        //   })
        //
        // return this.data_exports
            
        return this.data_sales_detail.map((e)=>({
            TGL_PESAN: e.TglPesan,
            INV_NO: e.CustomerOrderNo,
            ITEM: e.NamaBarang,
            LENGTH: e.Panjang,
            QTY: e.Lembar,
            ITEM_PRICE: e.HargaSatuan,
            TOTAL: e.Total,
        }));

    },
    startDownload(){
        this.$store.dispatch('setOverlay', true)
    },
    finishDownload(){
        this.$store.dispatch('setOverlay', false)
    },

    handleRegionClick(){
        this.getOffice()
        this.getSubRegion()
    },

    async getOffice(){
      var urlFilter = '?'

      if(this.region != '' && this.region != null){
        urlFilter = `${urlFilter}region_id=${this.region}`
      }


      this.offices=[]
      await axios.post(`${process.env.VUE_APP_URL}/api/kri/master/getOffice`, {
        region_id : this.region ? this.region : "",
        subregion : this.subregion ? this.subregion : "",
        office_id : this.office ? this.office : "",
      }, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
      })
      .then(res => {
          this.offices  = res.data.data
      })
    },

    async getSubRegion(){
      var urlFilter = '?'
      if(this.region != '' && this.region != null){
        urlFilter = `${urlFilter}region_id=${this.region}`
      }


      this.subregions=[]
      await axios.post(`${process.env.VUE_APP_URL}/api/kri/master/getSubRegion`, {
        region : this.region ? this.region : "",
        subregion : this.subregion ? this.subregion : ""
      }, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
      })
      .then(res => {
          this.subregions  = res.data.data
      })
    },

    async getRegion(){
      // var respData = await kriMaster.getRegion(``, null, false, false, false)
      // if (respData.status === 200) {
      //     this.regions = respData.data.data
      // }
      this.regions=[]
      await axios.post(`${process.env.VUE_APP_URL}/api/kri/master/getRegion`, {

      }, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
      })
      .then(res => {
          this.regions  = res.data.data
      })
    },
    
    close_inv_payment(){
      this.dialog_inv_payment = false
    },

    addSymbols(e) {
      var suffixes = ["", "K", "M", "B"];
      var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

      if(order > suffixes.length - 1)
          order = suffixes.length - 1;

      var suffix = suffixes[order];
      return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
    }, 
    toggleDataSeries(e) {
        if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
            e.dataSeries.visible = false;
        } else {
            e.dataSeries.visible = true;
        }
        e.chart.render();
    }, 
    toggleDataPointVisibility(e) {
        if(e.dataPoint.hasOwnProperty("actualYValue") && e.dataPoint.actualYValue !== null) {
            e.dataPoint.y = e.dataPoint.actualYValue;
            e.dataPoint.actualYValue = null;
            e.dataPoint.indexLabelFontSize = null;
            e.dataPoint.indexLabelLineThickness = null;
            e.dataPoint.legendMarkerType = "circle";
        } 
        else {
            e.dataPoint.actualYValue = e.dataPoint.y;
            e.dataPoint.y = 0;
            e.dataPoint.indexLabelFontSize = 0;
            e.dataPoint.indexLabelLineThickness = 0; 
            e.dataPoint.legendMarkerType = "cross";
        }
        e.chart.render();
    }, 
    showDefaultText(chart, text) {
      var dataPoints = chart.options.data[0].dataPoints;
      var isEmpty = !(dataPoints && dataPoints.length > 0);

      if (!isEmpty) {
          for (var i = 0; i < dataPoints.length; i++) {
          isEmpty = !dataPoints[i].y;
          if (!isEmpty)
              break;
          }
      }

      if (!chart.options.subtitles)
          chart.options.subtitles = [];
      if (isEmpty) {
          chart.options.subtitles.push({
          text: text,
          verticalAlign: 'center',
          });
          chart.options.data[0].showInLegend = false;
      } else {
          chart.options.data[0].showInLegend = true;
      }
    },

    getPieChart(dt_detail, container_detail, title_detail, label_detail, labelNoData){
      var pie_chart = new CanvasJS.Chart(container_detail, {
          animationEnabled: true,
          exportEnabled: true,
          theme: "light2",
          exportEnabled: true,
          title: {
              text: title_detail,
              fontFamily: "Calibri",
              fontSize: 20
          },
          exportEnabled: true,
          subtitles:[
              {
                  text: label_detail,
                  //Uncomment properties below to see how they behave
                  fontFamily: "Calibri",
                  fontColor: "red",
                  fontSize: 12
              }
          ],
          legend: {
              itemclick: this.toggleDataPointVisibility
          },
          data: [{

              type: "pie",
              percentFormatString: "#0.##",
              indexLabel: "{label} #percent%",
              indexLabelFontSize: 12,

          }]
      });
      pie_chart.options.data[0].dataPoints = dt_detail;
      this.showDefaultText(pie_chart, labelNoData);

      if (this.x.matches) {

          for(var i = 0; i < pie_chart.options.data.length; i++){
              pie_chart.options.data[i].indexLabelFontSize = 6;
          }
          pie_chart.render();
      }
      pie_chart.render();
    },

    getChart(dt1, container, title, label, data1){
        var chart = new CanvasJS.Chart(container, {
            animationEnabled: true,
            theme: "light2",
            exportEnabled: true,
            title: {
                text: title,
                fontFamily: "Calibri",
                fontSize: 20
            },
            exportEnabled: true,
            subtitles:[
                {
                    text: label,
                    //Uncomment properties below to see how they behave
                    fontFamily: "Calibri",
                    fontColor: "red",
                    fontSize: 12
                }
            ],
            axisY: {
                crosshair: {
                    enabled: true,
                    snapToDataPoint: true
                },
                title: "Person",
                labelFormatter: this.addSymbols,
            },
            toolTip:{
                shared:true
            },
            legend: {
                cursor: "pointer",
                itemclick: this.toggleDataSeries,
                fontSize: 11
            },
            data: [ data1]
        });
        chart.options.data[0].dataPoints = dt1;
        // chart.options.data[1].dataPoints = dt2;
        this.showDefaultText(chart, "No Data Found!");
        var x = window.matchMedia("(max-width: 991px)");
        if (x.matches) {

            for(var i = 0; i < chart.options.data.length; i++){
                chart.options.data[i].indexLabelFontSize = 8;
            }
            chart.render();
        }
        chart.render();  
    },
    async getSalesAnalysis(){
      this.$store.dispatch('setOverlay', true)

      // -- clear chart daily omset, office omset, list detail
      this.renderChart2([]);
      this.data_sales_office = [];
      this.data_sales_detail = [];

      await axios.get(`${process.env.VUE_APP_URL}/api/kri/sales/salesAnalysis/salesSum?region=${this.region ?? ''}&subregion=${this.subregion ?? ''}&office=${this.office ?? ''}`, { 
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*',
                  Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
          })
          .then(res => {
            
            this.renderChart1(res.data.data.sale, res.data.data.retur)
          })
      this.$store.dispatch('setOverlay', false)
    },
    renderChart1(data1, data2){
      var chart1 = new CanvasJS.Chart("chartContainer1", {
              animationEnabled: true,
              theme: "light2",
              axisY: {
                  title: "IDR",
                  titleFontSize: 24
              },
              data: [{
                  type: "column",
                  indexLabel: "{y}",
                  name: "Last Year",
                  showInLegend: true,
                  indexLabelPlacement: "inside",  
                  indexLabelOrientation: "vertical",
                  color: "#f79b07",
                  yValueFormatString: "###,###",
                  dataPoints: data1,
                  click: this.onTableClick1,
              },
              {
                  type: "column",
                  indexLabel: "{y}",
                  name: "This Year",
                  showInLegend: true,
                  indexLabelPlacement: "inside",  
                  indexLabelOrientation: "vertical",
                  color: "#07dbf7",
                  yValueFormatString: "###,###",
                  dataPoints: data2,
                  click: this.onTableClick1,
              }]
          })
        chart1.render()
    },
    renderChart2(data1){
    var chart2 = new CanvasJS.Chart("chartContainer2", {
            animationEnabled: true,
            theme: "light2",
            axisY: {
                title: "IDR",
                titleFontSize: 24
            },
            data: [{
                type: "column",
                indexLabel: "{y}",
                name: "Last Year",
                showInLegend: true,
                indexLabelPlacement: "inside",  
                indexLabelOrientation: "vertical",
                color: "#0aff0b",
                yValueFormatString: "###,###",
                dataPoints: data1,
                click: this.onTableClick2,
            }]
        })
    chart2.render()
    },
    async onTableClick1(event){
        this.$store.dispatch('setOverlay', true)
                
        await axios.get(`${process.env.VUE_APP_URL}/api/kri/sales/salesAnalysis/dailySum?region=${this.region ?? ''}&subregion=${this.subregion ?? ''}&office=${this.office ?? ''}&period=${event.dataPoint.period}`, { 
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                // -- render chart daily, office omzet
                this.renderChart2(res.data.data.sale);
                this.onTableClick2(event);
            })
            
        this.$store.dispatch('setOverlay', false)
    },
    async onTableClick2(event){
        this.$store.dispatch('setOverlay', true)

        // -- clear sales detail
        this.data_sales_detail = [];
                
        // await axios.post(`${process.env.VUE_APP_URL}/api/kri/sales/salesAnalysis/officeSum`, {
        //     'period': event.dataPoint.period,
        //     'region' : this.region,
        //     'subregion' : this.subregion,
        //     'office' : this.office
        // }, { 
        //     headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        // })
        // .then(res => {
        //     this.data_sales_office  = res.data.data
        // })
        // console.log(this.data_sales_office);

        let reqBody = {
          'period': event.dataPoint.period,
          'region' : this.region ?? '',
          'subregion' : this.subregion ?? '',
          'office_id' : this.office ?? ''
        }
        const respData = await salesAnalysis.officeSum('', reqBody)
        if (respData.status === 200) {
            this.data_sales_office = respData.data.data
            this.$store.dispatch('setOverlay', false)
        } else {
            this.$store.dispatch('setOverlay', false)
            this.snackbar = {
                color: "error",
                icon: "mdi-alert-circle",
                mode: "multi-line",
                position: "top",
                timeout: 7500,
                title: "Warning",
                text: 'Failed to retrieve data',
                visible: true
            };
            // this.$store.dispatch('setOverlay', false)
        }
            
        // return this.data_exports

        // this.$store.dispatch('setOverlay', false)
    },
    
  },
  watch: {
      option_item_other_payments:{
        handler () {
            // this.loading_other_payment = true
            const { page, itemsPerPage } = this.option_item_other_payments
            if (this.period_other_payment) {
                this.getItemOtherPayment(page, itemsPerPage, this.period_other_payment)
            }
        },
        deep: true,
      },
      option_item_payments:{
        handler () {
              // this.loading_payment = true
              const { page, itemsPerPage } = this.option_item_payments
              if (this.period) {
                  this.getItemPayment(page, itemsPerPage, this.period)
              }
          },
          deep: true,
      },

      option_item_shops:{
        handler () {
              // this.loading_shop_last_month = true
              const { page, itemsPerPage } = this.option_item_shops
              if (this.last_month) {
                  this.getItemShop(page, itemsPerPage, this.last_month)
              }
          },
          deep: true,
      },
  }
}
</script>