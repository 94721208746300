import {backendApi} from "../backend-api-sr"

export const salesAnalysis = (() =>{
    const getAditive = ( async (subPath, body) => {
        var url = '/api/kri/sales/salesAnalysis/aditive'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, body, false, false, false)
    })

    const getPie = ( async (subPath, body) => {
        var url = '/api/kri/sales/salesAnalysisV2/sum-pie'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, body, false, false, false)
    })

    const getSumOfficeSales = ( async (subPath, body) => {
        var url = '/api/kri/sales/salesAnalysisV2/sum-office-sales'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, body, false, false, false)
    })

    const getSumOfficeRetur = ( async (subPath, body) => {
        var url = '/api/kri/sales/salesAnalysisV2/sum-office-retur'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, body, false, false, false)
    })

    const getIndex = ( async (subPath, body) => {
        var url = '/api/kri/sales/salesAnalysisV2'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, body, false, false, false)
    })

    const getIndexRetur = ( async (subPath, body) => {
        var url = '/api/kri/sales/salesAnalysisV2/index-retur'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, body, false, false, false)
    })

    const officeSum = ( async (subPath, body) => {
        var url = '/api/kri/sales/salesAnalysis/officeSum'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, body, false, false, false)
    })

    const officeSumDetails = ( async (subPath, body) => {
        var url = '/api/kri/sales/salesAnalysis/officeSumDetails'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, body, false, false, false)
    })

    return {getAditive, getPie, getSumOfficeSales, getSumOfficeRetur, getIndex, getIndexRetur,officeSum,officeSumDetails};
})()